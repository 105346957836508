import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Col, Row } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { ReactComponent as AnalysedLogoSVG } from "images/analysed-logo.svg";
import { StyledLink, StyledLogoWrapper } from "../../styles.";

const LeftMenu = () => {
  const screens = useBreakpoint();

  const { pathname } = useLocation();

  return (
    <>
      {screens.lg ? (
        <Row>
          <Col lg={{ span: 4 }} span={4}>
            <StyledLogoWrapper>
              <Link to="/">
                <AnalysedLogoSVG />
              </Link>
            </StyledLogoWrapper>
          </Col>
          <Col lg={{ span: 4 }} xl={{ span: 4 }} span={4}>
            <StyledLink isActive={pathname === "/projects"} to="/projects">
              Our Projects
            </StyledLink>
          </Col>
          <Col lg={{ span: 4 }} xl={{ span: 4 }} span={4}>
            <StyledLink isActive={pathname === "/services"} to="/services">
              Services
            </StyledLink>
          </Col>
          <Col lg={{ span: 4 }} xl={{ span: 4 }} span={4}>
            <StyledLink isActive={pathname === "/about"} to="about">
              About Us
            </StyledLink>
          </Col>
          <Col lg={{ span: 4 }} xl={{ span: 4 }} span={4}>
            <StyledLink isActive={pathname === "/careers"} to="careers">
              Careers
            </StyledLink>
          </Col>
          <Col span={4}>
            <StyledLink isActive={pathname === "/contact"} to="/contact">
              Contact
            </StyledLink>
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col lg={{ span: 5 }} span={4}>
              <Link to="/">
                <AnalysedLogoSVG />
              </Link>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default LeftMenu;
