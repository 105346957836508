import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button, Drawer } from "antd";

export const StyledHeaderWrapper = styled.div`
  padding: 18px 152px 0;
  border-bottom: 1px solid #b9b9b9;

  @media only screen and (max-width: 1200px) {
    padding: 12px 48px 0;
  }

  @media only screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    padding: 12px 24px 0;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 992px) {
    display: flex;
    align-items: center;
    padding: 12px 24px 0 48px;
  }
`;

export const StyledBurgerMenuSection = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledDiv = styled.div`
  margin-left: auto;
`;

export const StyledBurgerMenu = styled(Button)`
  &&& {
    background: none;
    box-shadow: none;
    border: none;
    border-left: 1px solid #b9b9b9;
    height: 100%;
    border-radius: unset;
    padding-right: 0;
    &:hover {
      border-color: #b9b9b9;
    }
  }

  @media only screen and (max-width: 992px) {
    padding-left: 24px;
  }
`;

export const StyledDrawer = styled(Drawer)`
  background: transparent !important;
  .ant-drawer-content-wrapper {
    height: 100%;
  }
  .ant-drawer-wrapper-body {
    background: linear-gradient(164deg, rgba(140, 140, 140, 0.8) 0%, rgba(140, 140, 140, 0.6) 100%);
    backdrop-filter: blur(12px);
  }
  .ant-drawer-close {
    svg path {
      fill: #fff;
    }
    margin-right: 0;
    padding: 0;
    margin-left: auto;
    font-size: 24px;
  }
  .ant-drawer-header {
    border: none;
    padding: 32px 24px 24px;
  }

  .ant-drawer-body {
    padding: 4px 38px;
  }
`;

export const StyledLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledMenuLink = styled(Link)`
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 48px;
  &:hover {
    color: #fff;
  }
`;

export const StyledLink = styled(Link)<{ isActive: boolean }>`
  color: ${(props) => (props.isActive ? "#ffffff" : "#909090")};
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin-left: 32px;
  text-decoration: none;

  &:hover {
    color: #ffffff;
  }
`;

export const StyledLogoWrapper = styled.div`
  border-right: 1px solid #b9b9b9;
  padding-bottom: 8px;
`;
