import styled from "styled-components";
import { Typography } from "antd";
import { ReactComponent as LinkedinIcon } from "images/linkedin.svg";
import { ReactComponent as FacebookIcon } from "images/facebook.svg";

const Text = Typography;

export const StyledFooterWrapper = styled.div`
  padding: 32px 152px;
  margin-top: auto;
  border-top: 1px solid #b9b9b9;

  @media only screen and (max-width: 992px) {
    padding: 24px 32px 74px;
  }

  @media only screen and (min-device-width: 993px) and (max-device-width: 1200px) {
    padding: 24px 48px 74px;
  }
`;

export const StyledText = styled(Text)`
  color: #9d9d9d;
  font-weight: 600;
  text-transform: uppercase;
  padding-top: 16px;
`;

export const StyledTitle = styled(Text)`
  color: #787878;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.28px;
  @media only screen and (max-width: 992px) {
    padding-top: 24px;
  }
`;

export const StyledSubTitle = styled(Text)`
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding-top: 16px;
  a {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding-top: 16px;
    &:hover {
      color: #fff;
      text-decoration: underline;
    }
    &:focus {
      color: #fff !important;
    }
  }
`;

export const StyledMediaWrapper = styled.div`
  @media only screen and (max-width: 1200px) {
    padding-top: 24px;
  }
`;

export const StyledLinkedinIcon = styled(LinkedinIcon)`
  margin-right: 24px;
`;

export const StyledFacebookIcon = styled(FacebookIcon)``;
