import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { Outlet } from "react-router";

const Home = lazy(() => import(/* webpackChunkName: "Home" */ "pages/Home"));
const About = lazy(() => import(/* webpackChunkName: "About" */ "pages/About"));
const Contact = lazy(() => import(/* webpackChunkName: "Contact" */ "pages/Contact"));
const Careers = lazy(() => import(/* webpackChunkName: "Careers" */ "pages/Careers"));
const Projects = lazy(() => import(/* webpackChunkName: "Projects" */ "pages/Projects"));
const Araks = lazy(() => import("pages/Projects/OurProjects/araks"));
const Meetk = lazy(() => import("pages/Projects/OurProjects/meetk"));
const Proflab = lazy(() => import("pages/Projects/OurProjects/proflab"));
const Special = lazy(() => import("pages/Projects/OurProjects/special"));
const ZinHub = lazy(() => import("pages/Projects/OurProjects/zinHub"));
const Pixelait = lazy(() => import("pages/Projects/OurProjects/pixelait"));
const Political = lazy(() => import("pages/Projects/OurProjects/political"));
const Services = lazy(() => import(/* webpackChunkName: "Services" */ "pages/Services"));

const Router = () => {
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <>
      <Suspense fallback={<Spin indicator={loadingIcon} />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/projects" element={<Outlet />}>
            <Route index element={<Projects />} />
            <Route path="araks" element={<Araks />} />
            <Route path="meetk" element={<Meetk />} />
            <Route path="proflab" element={<Proflab />} />
            <Route path="special" element={<Special />} />
            <Route path="zinHub" element={<ZinHub />} />
            <Route path="pixelait" element={<Pixelait />} />
            <Route path="political" element={<Political />} />
          </Route>
          <Route path="/careers" element={<Careers />} />
          <Route path="/services" element={<Services />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default Router;
