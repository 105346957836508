import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "antd";
import { ReactComponent as AnalysedLogoSVG } from "images/analysed-logo.svg";

import {
  StyledFooterWrapper,
  StyledText,
  StyledTitle,
  StyledSubTitle,
  StyledMediaWrapper,
  StyledLinkedinIcon,
  StyledFacebookIcon
} from "./styles";
const Footer = () => {
  return (
    <StyledFooterWrapper>
      <Row>
        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 5 }}>
          <Link to="/">
            <AnalysedLogoSVG />
          </Link>
          <StyledText>Turning Ideas into Reality </StyledText>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10 }} xl={{ span: 7 }}>
          <StyledTitle>OUR ADDRESS</StyledTitle>
          <StyledSubTitle>
            <a href="https://www.google.com/maps/dir/2/5 Armenak Armenakyan str, Yerevan, Armenia">
              2/5 Armenak Armenakyan str
              <br /> Yerevan, Armenia
            </a>
          </StyledSubTitle>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 4 }}>
          <StyledTitle>OUR CONTACTS</StyledTitle>
          <StyledSubTitle>
            <a href="tel:+(374) 55 303 516">+(374) 55 303 516</a>
          </StyledSubTitle>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 4 }}>
          <StyledTitle>SEND US E-MAIL</StyledTitle>
          <StyledSubTitle>
            <a href="mailto: info@analysed.ai">info@analysed.ai</a>
          </StyledSubTitle>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }} xl={{ span: 4 }}>
          <StyledMediaWrapper>
            <Link to="https://am.linkedin.com/company/analyseda">
              <StyledLinkedinIcon />
            </Link>
            <Link to="https://www.facebook.com/analysed2022">
              <StyledFacebookIcon />
            </Link>
          </StyledMediaWrapper>
        </Col>
      </Row>
    </StyledFooterWrapper>
  );
};

export default Footer;
