import React, { useState } from "react";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { ReactComponent as BurgerMenu } from "images/burger-menu.svg";
import LeftMenu from "./components/LeftMenu";
import {
  StyledHeaderWrapper,
  StyledBurgerMenuSection,
  StyledDiv,
  StyledBurgerMenu,
  StyledDrawer,
  StyledLinksWrapper,
  StyledMenuLink
} from "./styles.";

const Header = () => {
  const [open, setOpen] = useState(false);
  const screens = useBreakpoint();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <StyledHeaderWrapper>
      <LeftMenu />
      {!screens.lg && (
        <StyledBurgerMenuSection>
          <StyledDiv>
            <StyledBurgerMenu onClick={showDrawer}>
              <BurgerMenu />
            </StyledBurgerMenu>
          </StyledDiv>
        </StyledBurgerMenuSection>
      )}
      <StyledDrawer onClick={() => setOpen(false)} placement="right" open={open} onClose={onClose}>
        <StyledLinksWrapper>
          <StyledMenuLink to="/">Home</StyledMenuLink>
          <StyledMenuLink to="/projects">Our Projects</StyledMenuLink>
          <StyledMenuLink to="/services">Services</StyledMenuLink>
          <StyledMenuLink to="/about">About us</StyledMenuLink>
          <StyledMenuLink to="/careers">Careers</StyledMenuLink>
          <StyledMenuLink to="/contact">Contact</StyledMenuLink>
        </StyledLinksWrapper>
      </StyledDrawer>
    </StyledHeaderWrapper>
  );
};

export default Header;
