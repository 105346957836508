import React from "react";
import "App.css";
import Header from "components/Header";
import Footer from "components/Footer";
import Router from "components/Router";
import { StyledMainWrapper } from "sytles";

function App() {
  return (
    <StyledMainWrapper>
      <Header />
      <Router />
      <Footer />
    </StyledMainWrapper>
  );
}

export default App;
